<template>
  <WelcomeBonusCard
    :badge="t('welcomeBonus.freebetBadge')"
    :amount="welcomeBonus.freebetAmount"
    :currency-id="welcomeBonus.freebetCurrencyId"
    :title="t('welcomeBonus.freebet')"
    :is-mobile="props.isMobile"
    type="freebet"
  >
    <template #tooltip>
      <FreebetTooltip :freebet="preparedWelcomeBonus" />
    </template>
  </WelcomeBonusCard>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import FreebetTooltip from '@st/bonuses/components/FreeBonusCard/parts/FreebetTooltip.vue'
import WelcomeBonusCard from './WelcomeBonusCard.vue'
import type { WelcomeBonus } from '../types'

const props = defineProps<{
  welcomeBonus: WelcomeBonus
  isMobile?: boolean
}>()
const { t } = useI18n()

const preparedWelcomeBonus = computed<any>(() => ({
  freebetRollingSettings: {
    rollingMultiplier: props.welcomeBonus.freebetRollingMultiplier,
    rollingPeriodInHours: props.welcomeBonus.freebetRollingPeriodInHours,
    rollingMaxBetRate: props.welcomeBonus.freebetRollingRollingMaxBetRate,
    rollingMinBetRate: props.welcomeBonus.freebetRollingRollingMinBetRate,
    rollingCasinoCategoryIds:
      props.welcomeBonus.freebetRollingRollingCasinoCategoryIds,
  },
  ...props.welcomeBonus,
}))
</script>
