<template>
  <WelcomeBonusCard
    :badge="badge"
    :amount="amount"
    :title="title"
    :is-mobile="isMobile"
    type="freespin"
  >
    <template #tooltip>
      <FreespinTooltip :freespin="preparedWelcomeBonus" />
    </template>
  </WelcomeBonusCard>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCurrencies } from '@st/payments/stores/currencies'
// eslint-disable-next-line import/no-extraneous-dependencies
import FreespinTooltip from '@st/bonuses/components/FreeBonusCard/parts/FreespinTooltip.vue'
import WelcomeBonusCard from './WelcomeBonusCard.vue'
import type { WelcomeBonus } from '../types'

const props = defineProps<{
  welcomeBonus: WelcomeBonus
  isMobile?: boolean
}>()

const { getCurrencyById } = useCurrencies()
const { t } = useI18n()

const { format } = useCurrencyFormatter({
  currency: computed(() => {
    const currencyId = props.welcomeBonus.freespinProgramCurrencyId as number
    return getCurrencyById(currencyId)?.code ?? 'USD'
  }),
})

const badge = computed(() => {
  let denomination = 0
  if (props.welcomeBonus.freespinAmount && props.welcomeBonus.freespinNumber) {
    denomination = +props.welcomeBonus.freespinAmount
  }

  const formattedAmount = format(denomination)
  return t('welcomeBonus.freespinBadge', { amount: formattedAmount })
})

const title = computed(() =>
  t('welcomeBonus.freespinsTitle', {
    amount: props.welcomeBonus.freespinNumber,
  }),
)

const amount = computed(() => {
  if (props.welcomeBonus.freespinAmount && props.welcomeBonus.freespinNumber) {
    const sum =
      +props.welcomeBonus.freespinAmount * props.welcomeBonus.freespinNumber

    return format(sum)
  }
  return ''
})

const preparedWelcomeBonus = computed<any>(() => ({
  rollingMultiplier: props.welcomeBonus.freespinProgramRollingMultiplier,
  rollingDurationPeriodInHours:
    props.welcomeBonus.freespinProgramRollingDurationPeriodInHours,
  rollingMinBetRate: props.welcomeBonus.freespinProgramRollingMinBetRate,
  rollingMaxBetRate: props.welcomeBonus.freespinProgramRollingMaxBetRate,
  rollingCasinoCategoryIds:
    props.welcomeBonus.freespinProgramRollingCasinoCategoryIds,
  freespinCurrencyId: props.welcomeBonus.freespinProgramCurrencyId,
  ...props.welcomeBonus,
}))
</script>
