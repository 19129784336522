<template>
  <div
    class="registration-wrapper"
    :class="{ mobile: props.isMobile, landing: props.isLanding }"
    data-t="registration"
  >
    <WelcomeBanner
      v-if="!props.isMobile && !props.isLanding"
      key="welcome-banner"
    />

    <div class="registration-form-wrapper">
      <MFormHeader
        v-if="isMobileHeaderVisible"
        class="form-header"
        with-back-button
        data-t="form-header"
        @back="handleSecondStepGoBack"
      >
        <p class="header-text">{{ t('authorization.registration') }}</p>
      </MFormHeader>
      <Steps :steps="steps" :is-mobile="props.isMobile" />
      <div class="registration-form">
        <FirstStep
          v-if="isCurrent(0)"
          :is-mobile="props.isMobile"
          :is-google-enabled="isGoogleEnabled"
          :is-email-enabled="isEmailEnabled"
          @success="handleFirstStepSuccess"
          @finish-tg="handleTgRegistrationFinish"
        />
        <SecondStep
          v-else-if="isCurrent(1) && firstStepData"
          :first-step-data="firstStepData"
          :is-mobile="props.isMobile"
          @success="handleSecondStepSuccess"
          @back="handleSecondStepGoBack"
        />
        <WelcomeBonus v-else :is-mobile="props.isMobile" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import { useSettingsStore } from '@st/core/stores/useSettingsStore'
import FirstStep from './parts/FirstStep.vue'
import SecondStep from './parts/SecondStep.vue'
import WelcomeBonus from '../WelcomeBonus/WelcomeBonus.vue'
import Steps from './parts/Steps.vue'
import MFormHeader from '../parts/MFormHeader.vue'
import WelcomeBanner from './parts/WelcomeBanner.vue'
import { useRegistrationStepper } from '../../composables/useRegistrationStepper'

interface FirstStepData {
  email: string
  password: string
}

const { t } = useI18n()

const props = withDefaults(
  defineProps<{
    isMobile?: boolean
    isLanding?: boolean
  }>(),
  {
    isMobile: false,
    isLanding: false,
  },
)
const { steps, current, goToNext, goToPrevious, isLast, goTo, isCurrent } =
  useRegistrationStepper()

const isMobileHeaderVisible = computed(
  () => props.isMobile && current.value && !isLast.value,
)

const { fetchGeoAvailability } = useCheckGeoAvailability()

const firstStepData = ref<FirstStepData>()

const emit = defineEmits<{
  (e: 'success'): void
  (e: 'stepChanged', value: number): void
}>()

function handleFirstStepSuccess(data: FirstStepData) {
  goToNext()
  firstStepData.value = data
  emit('stepChanged', current.value)
}

function handleSecondStepSuccess() {
  goToNext()
  emit('stepChanged', current.value)
}

function handleSecondStepGoBack() {
  goToPrevious()
  emit('stepChanged', current.value)
}

const { settings } = storeToRefs(useSettingsStore())
const isGoogleEnabled = computed(
  () => !!settings.value?.oidcGoogleRegistrationEnabled,
)

const isEmailEnabled = computed(
  () => !!settings.value?.emailRegistrationEnabled,
)

function handleTgRegistrationFinish() {
  goTo(2)
}

onMounted(async () => {
  await delay(500)
  fetchGeoAvailability()
})
</script>

<style scoped>
.registration-form-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 480px;
  min-height: 698px;
  margin-left: auto;
}

.registration-form {
  display: flex;
  flex-direction: column;
  flex-grow: 1;

  height: 100%;
  padding: var(--spacing-300) var(--spacing-600);
}

.registration-wrapper {
  display: flex;
  gap: var(--spacing-0);
  align-items: center;

  width: 856px;
  height: 698px;
  padding: var(--spacing-100);

  background-color: var(--background-base);
  border-radius: var(--border-radius-200);

  &.landing {
    width: auto;
    height: auto;
    padding: 0;
    border-radius: var(--border-radius-300);
  }

  &.mobile {
    width: 100%;
    height: 100%;
    padding: 0;

    background: var(--background-base);
    border-radius: 0;

    .registration-form {
      padding: 0 var(--spacing-200);
      padding-bottom: var(--spacing-200);
    }

    .registration-form-wrapper {
      width: 100%;
      height: 100%;
      min-height: 0;
    }
  }

  &.mobile.landing {
    border-radius: var(--border-radius-150);
  }
}

.header-text {
  margin: 0;
  padding: var(--spacing-100) 0;
  font: var(--mobile-title-2-semibold);
}
</style>
