<template>
  <div class="tooltip-content">
    <span class="tooltip-title">{{ t('bonuses.tooltipTitleCasino') }}</span>
    <div class="tooltip-text">{{ t('bonuses.reloadTip') }}</div>
    <NuxtI18nLink class="read-more" :to="readMoreUrl" target="_blank">
      {{ t('bonuses.readMore') }}
    </NuxtI18nLink>
  </div>
</template>

<script setup lang="ts">
const { t } = useI18n()
const readMoreUrl = usePublicFileUrl('publicFileReloadBonusTerms')
</script>

<style scoped>
.tooltip-content {
  display: flex;
  flex-direction: column;

  max-width: 300px;
  padding: var(--spacing-150) var(--spacing-200);

  font: var(--desktop-text-sm-semibold);
}

.tooltip-title {
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}

.tooltip-text {
  margin-bottom: var(--spacing-150);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  p {
    margin-top: var(--spacing-075);
    margin-bottom: 0;
  }

  ol {
    padding-left: var(--spacing-200);
  }
}

.read-more {
  cursor: pointer;
  color: var(--text-link);
  text-decoration: none;
}
</style>
