import { delay } from '@st/utils'
import type { WelcomeBonus } from './types'

export function useWelcomeBonus() {
  const welcomeBonus = ref<WelcomeBonus>({
    freebetAmount: null,
    freebetCurrencyId: null,
    freebetRollingMultiplier: null,
    freebetRollingPeriodInHours: null,
    freebetRollingRollingCasinoCategoryIds: null,
    freebetRollingRollingMaxBetRate: null,
    freebetRollingRollingMinBetRate: null,

    reloadProgramAmount: null,
    reloadProgramCurrencyId: null,
    reloadProgramRepeatIntervalInHours: null,

    freespinAmount: null,
    freespinProgramCurrencyId: null,
    freespinNumber: null,
    freespinProgramRollingMultiplier: null,
    freespinProgramRollingDurationPeriodInHours: null,
    freespinProgramRollingCasinoCategoryIds: null,
    freespinProgramRollingMinBetRate: null,
    freespinProgramRollingMaxBetRate: null,

    bonusForDepositDepositMultiplier: null,
    bonusForDepositCurrencyId: null,
    bonusForDepositMinDepositAmountInAppCurrency: null,
    bonusForDepositMaxBonusAmountInBonusCurrency: null,
    bonusForDepositRollingCasinoCategoryIds: null,
    bonusForDepositRollingMinBetRate: null,
    bonusForDepositRollingMaxBetRate: null,
  })

  const stFetch = useRawStFetch()

  const isLoading = ref(true)
  const isReady = computed(() => !isLoading.value)

  const hasFreebet = computed(() => !!welcomeBonus.value?.freebetAmount)
  const hasReload = computed(() => !!welcomeBonus.value?.reloadProgramAmount)
  const hasFreespin = computed(() => !!welcomeBonus.value?.freespinAmount)
  const hasDepositBonus = computed(
    () => !!welcomeBonus.value?.bonusForDepositDepositMultiplier,
  )

  const hasAnyBonus = computed(
    () =>
      hasFreebet.value ||
      hasReload.value ||
      hasFreespin.value ||
      hasDepositBonus.value,
  )

  async function fetchWelcomeBonus() {
    isLoading.value = true

    welcomeBonus.value = (await stFetch('/welcome-program/info/get', {
      method: 'post',
    })) as any

    await nextTick()

    if (hasAnyBonus.value) {
      isLoading.value = false
      return
    }

    await delay(3000)

    welcomeBonus.value = (await stFetch('/welcome-program/info/get', {
      method: 'post',
    })) as any

    isLoading.value = false
  }

  return {
    welcomeBonus,
    hasAnyBonus,
    hasFreebet,
    hasFreespin,
    hasDepositBonus,
    hasReload,
    isLoading,
    isReady,
    fetchWelcomeBonus,
  }
}
