<template>
  <article class="bonus" :class="{ mobile: isMobile }">
    <StTooltip class="bonus-tooltip" placement="top">
      <template #activator>
        <img :src="tooltipImg" alt="i" />
      </template>
      <DepositBonusTooltip :bonus="preparedDepositBonus" />
    </StTooltip>
    <div class="circle-check">
      <StIcon name="check" :size="isMobile ? 9 : 12" />
    </div>
    <img
      class="bonus-image"
      :src="depositImg"
      alt="bonus"
      :width="isMobile ? 48 : 60"
    />
    <h2 class="bonus-title">{{ t('bonuses.depositTitle') }}</h2>
    <p class="bonus-multiplier">{{ bonusMultiplier }}</p>
    <div class="rules-wrapper">
      <ul class="bonus-rules">
        <li>
          <StIcon v-if="!isMobile" name="check" size="16" />
          <p>
            {{ t('bonuses.depositMin') }}
            <b>{{
              t('bonuses.depositMinValue', { amount: minDepositAmount })
            }}</b>
          </p>
        </li>
        <li>
          <StIcon v-if="!isMobile" name="check" size="16" />
          <p>
            {{ t('bonuses.depositMax') }}
            <b>
              {{
                t('bonuses.depositMaxValue', { amount: maxDepositBonusAmount })
              }}
            </b>
            <StIcon v-if="currencyIcon" size="16" :name="currencyIcon" />
          </p>
        </li>
      </ul>
    </div>
  </article>
</template>

<script setup lang="ts">
import Decimal from '@st/decimal'
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCurrencies } from '@st/payments/stores/currencies'
// eslint-disable-next-line import/no-extraneous-dependencies
import DepositBonusTooltip from '@st/bonuses/components/DepositBonusTooltip/DepositBonusTooltip.vue'
import tooltipImg from '../assets/tooltip.svg'
import depositImg from '../assets/deposit.png'
import type { WelcomeBonus } from '../types'

const props = defineProps<{
  welcomeBonus: WelcomeBonus
  isMobile?: boolean
}>()

const currenciesStore = useCurrencies()
const { appCurrency, currencies } = storeToRefs(currenciesStore)
const { t } = useI18n()

const { format: formatCrypto } = useCryptoFormatter()

const { format: formatFiat } = useCurrencyFormatter({
  currency: computed(() => appCurrency.value.code),
})

const { format: formatMultiplier } = useNumberFormatter()

const bonusMultiplier = computed(
  () =>
    `+${formatMultiplier(
      new Decimal(props.welcomeBonus.bonusForDepositDepositMultiplier ?? 0)
        .mul(100)
        .toString(),
    )}%`,
)

const minDepositAmount = computed(() =>
  formatFiat(
    props.welcomeBonus.bonusForDepositMinDepositAmountInAppCurrency ?? 0,
  ),
)

const maxDepositBonusAmount = computed(() =>
  formatCrypto(
    props.welcomeBonus.bonusForDepositMaxBonusAmountInBonusCurrency ?? 0,
  ),
)

const currencyIcon = computed(() =>
  props.welcomeBonus.bonusForDepositCurrencyId
    ? currencies.value[props.welcomeBonus.bonusForDepositCurrencyId]?.icon
    : undefined,
)

const preparedDepositBonus = computed<any>(() => ({
  bonusSettings: {
    currencyId: props.welcomeBonus.bonusForDepositCurrencyId,
    maxBonusAmountInBonusCurrency:
      props.welcomeBonus.bonusForDepositMaxBonusAmountInBonusCurrency,
    rollingMultiplier: props.welcomeBonus.bonusForDepositDepositMultiplier,
    rollingPeriodInHours: '',
    rollingMinBetRate: props.welcomeBonus.bonusForDepositRollingMinBetRate,
    rollingMaxBetRate: props.welcomeBonus.bonusForDepositRollingMaxBetRate,
    rollingCasinoCategoryIds:
      props.welcomeBonus.bonusForDepositRollingCasinoCategoryIds,
  },
  ...props.welcomeBonus,
}))
</script>

<style scoped>
.bonus-image {
  margin-top: -30px; /* stylelint-disable-line */
}

.bonus-title {
  margin: 0;
  margin-top: var(--spacing-025);
  font: var(--desktop-text-sm-semibold);
}

.bonus-multiplier {
  margin: 0;

  font: var(--desktop-text-2xl-semibold);
  text-shadow: 0 4px 60px rgb(249 218 144 / 32%);

  background: linear-gradient(
    109deg,
    #ffe86d 17.82%,
    #f8db90 42.56%,
    #feb581 72.33%,
    #f09f7c 81.52%
  );
  background-clip: text;

  -webkit-text-fill-color: transparent;
}

.tooltip-content {
  display: flex;
  flex-direction: column;

  max-width: 300px;
  padding: var(--spacing-150) var(--spacing-200);

  font: var(--desktop-text-sm-semibold);
}

.tooltip-title {
  margin: 0;
  font: var(--desktop-text-sm-semibold);
  color: var(--text-primary);
}

.tooltip-text {
  margin-bottom: var(--spacing-150);
  font: var(--desktop-text-sm-medium);
  color: var(--text-secondary);

  p {
    margin-top: var(--spacing-075);
    margin-bottom: 0;
  }
}

.rules-wrapper {
  display: flex;
  justify-content: center;
  width: 100%;
  border-top: 1px solid var(--border-tertiary);
}

.bonus-rules {
  margin: 0;
  margin-top: var(--spacing-100);
  padding: 0;
  padding-top: var(--spacing-125);

  list-style: none;

  li {
    display: flex;
    gap: var(--spacing-075);
    align-items: center;

    min-height: 20px;

    font: var(--desktop-text-xs-semibold);
    color: var(--text-secondary);

    p {
      display: flex;
      gap: var(--spacing-025);
      align-items: center;
      margin: 0;
    }

    b {
      color: var(--text-primary);
    }
  }
}

.bonus-tooltip {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
}

.circle-check {
  position: absolute;
  z-index: 1;
  top: -5px;
  left: -5px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-050);

  color: var(--background-base);

  background: var(--icon-primary);
  border: var(--border-width-boldest) solid var(--background-base);
  border-radius: var(--border-radius-full);
}

.read-more {
  cursor: pointer;
  color: var(--text-link);
  text-decoration: none;
}

.bonus {
  position: relative;

  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: center;

  min-width: 250px;
  padding: var(--spacing-125) var(--spacing-150);

  background: radial-gradient(
      102.33% 102.33% at 50% 19.65%,
      rgb(253 46 0 / 70%) 0%,
      rgb(232 0 42 / 7%) 85.92%
    ),
    #20202a;
  background-color: var(--background-primary);
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-150);
  box-shadow: 0 8px 40px 0 rgb(0 0 0 / 32%);

  &.mobile {
    min-width: 216px;
    padding: var(--spacing-150) var(--spacing-100);

    .bonus-title {
      margin-top: var(--spacing-050);
      font: var(--mobile-caption-1-medium);
    }

    .bonus-multiplier {
      /* stylelint-disable */
      font-family: 'SF Pro Display';
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      line-height: 28px;

      text-align: center;
      /* stylelint-enable */
    }

    .bonus-rules {
      li {
        justify-content: center;
        min-height: 20px;
        font: var(--mobile-caption-1-medium);
      }
    }

    .circle-check {
      padding: 3.2px; /* stylelint-disable-line */
    }
  }
}
</style>
