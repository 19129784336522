<template>
  <div class="steps" :class="[{ mobile: props.isMobile }, props.type]">
    <div
      v-for="item in steps"
      :key="item.number"
      class="step"
      :class="{ 'active-step': item.isActive, 'passed-step': item.isPassed }"
    >
      <div class="step-number">
        <StIcon v-if="item.isPassed" name="check" class="icon" />
        <div v-else class="number">
          {{ item.number }}
        </div>
      </div>
      <div class="step-text">{{ item.text }}</div>
    </div>
  </div>
</template>
<script setup lang="ts">
import type { RegistrationStep } from '../../../types'

const props = defineProps<{
  steps: RegistrationStep[]
  type?: 'default' | 'separated'
  isMobile?: boolean
}>()
</script>
<style scoped>
.steps {
  display: flex;
  align-items: flex-start;
  align-self: stretch;

  width: 100%;
  padding: var(--spacing-300);

  .step {
    display: flex;
    flex: 1 0 auto;
    gap: var(--spacing-100);
    align-items: center;
    justify-content: center;

    padding: var(--spacing-0, 0);
  }

  .step-number {
    display: flex;
    align-items: center;
    justify-content: center;

    width: 26px;
    height: 26px;

    background-color: #1f1f29; /* stylelint-disable-line */
    border-radius: var(--border-radius-full);

    .number {
      font: var(--desktop-text-sm-semibold);
      color: var(--palette-light-1000);
      opacity: 0.64;
    }
  }

  .step-text {
    font: var(--desktop-text-sm-semibold);
    white-space: nowrap;
    opacity: 0.48;
  }

  .active-step {
    .step-number {
      opacity: 1;
      background-color: var(--palette-light-1000);

      .number {
        font: var(--desktop-text-sm-semibold);
        color: var(--background-base);
        opacity: 1;
      }
    }

    .step-text {
      opacity: 1;
    }
  }

  .passed-step {
    .step-number {
      opacity: 1;
      background-color: #28bd4829; /* stylelint-disable-line */

      .icon {
        width: var(--spacing-200);
        height: var(--spacing-200);

        font: var(--desktop-text-sm-semibold);
        color: var(--system-success);

        opacity: 1;
      }
    }

    .step-text {
      color: var(--palette-light-600);
      opacity: 1;
    }
  }

  &.mobile {
    justify-content: space-between;
    padding: var(--spacing-200);

    &.separated {
      gap: var(--spacing-400);
      justify-content: flex-start;
    }

    .step {
      flex-basis: 0;
      flex-grow: 0;
      gap: var(--spacing-075);
      justify-content: flex-start;

      border: none;
    }

    .step-number {
      width: 16px;
      height: 16px;

      .number {
        font: var(--mobile-caption-1-medium);
      }
    }

    .step-text {
      font: var(--mobile-caption-1-regular);
    }
  }

  &.separated {
    .step:not(:last-child) {
      border-right: 1px solid var(--border-primary);
    }
  }
}
</style>
