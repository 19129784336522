<template>
  <div class="bonus" :class="[type, { mobile: isMobile }]">
    <StTooltip class="bonus-tooltip" placement="top">
      <template #activator>
        <img :src="tooltipImg" alt="i" />
      </template>
      <div class="tooltip-content"><slot name="tooltip" /></div>
    </StTooltip>
    <div class="circle-check">
      <StIcon name="check" :size="isMobile ? 9 : 12" />
    </div>
    <img
      :src="image"
      alt="bonus"
      :width="isMobile ? 48 : 60"
      :height="isMobile ? 48 : 60"
      class="bonus-image"
    />
    <h2 class="bonus-title">{{ title }}</h2>
    <div v-if="placeholder" class="placeholder">
      {{ placeholder }}
    </div>
    <div v-else class="content">
      <p class="bonus-value">
        {{ amount }}
        <StIcon
          v-if="currencyIcon"
          :size="isMobile ? 20 : 24"
          :name="currencyIcon"
        />
      </p>
      <div v-if="badge" class="bonus-badge">{{ badge }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
// eslint-disable-next-line import/no-extraneous-dependencies
import { useCurrencies } from '@st/payments/stores/currencies'
import tooltipImg from '../assets/tooltip.svg'
import freebetImage from '../assets/freebet.png'
import freespinImage from '../assets/freespin.png'
import reloadImage from '../assets/reload.png'

const props = defineProps<{
  type: 'freebet' | 'freespin' | 'reload'
  currencyId?: number | null
  amount?: string | null
  badge?: string
  placeholder?: string
  title?: string
  isMobile?: boolean
}>()

const image = computed(
  () =>
    ({
      freebet: freebetImage,
      freespin: freespinImage,
      reload: reloadImage,
    })[props.type] ?? freebetImage,
)

const { currencies } = storeToRefs(useCurrencies())

const currencyIcon = computed(() =>
  props.currencyId ? currencies.value[props.currencyId]?.icon : undefined,
)
</script>

<style scoped>
.content {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;

  width: 100%;
}

.placeholder {
  padding: var(--spacing-050) var(--spacing-100) var(--spacing-200);
  font: var(--desktop-text-xs-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.bonus-image {
  margin-top: -30px; /* stylelint-disable-line */
}

.bonus-title {
  margin: 0;
  margin-top: var(--spacing-025);
  font: var(--desktop-text-sm-semibold);
}

.bonus-value {
  display: flex;
  gap: var(--spacing-050);
  align-items: center;

  margin: 0;
  margin-top: -2px; /* stylelint-disable-line */

  font: var(--desktop-text-xl-semibold);
}

.bonus-badge {
  margin-top: var(--spacing-150);
  padding: var(--spacing-025) var(--spacing-075);

  font: var(--desktop-text-xs-medium);
  color: var(--text-secondary);
  text-align: center;

  background: rgb(255 255 255 / 8%);
  border-radius: var(--border-radius-075, 6px);
}

.bonus-tooltip {
  position: absolute;
  z-index: 1;
  top: 10px;
  right: 10px;
}

.circle-check {
  position: absolute;
  z-index: 1;
  top: -5px;
  left: -5px;

  display: flex;
  align-items: center;
  justify-content: center;

  padding: var(--spacing-050);

  color: var(--background-base);

  background: var(--icon-primary);
  border: var(--border-width-boldest) solid var(--background-base);
  border-radius: var(--border-radius-full);
}

.bonus {
  position: relative;

  display: flex;
  flex: 1 0 0;
  flex-direction: column;
  align-items: center;

  min-width: 120px;
  max-width: 200px;
  padding: var(--spacing-125) var(--spacing-150);

  background-color: var(--background-primary);
  background-position: center;
  background-size: cover;
  border-radius: var(--border-radius-100);

  &.freebet {
    background-image: url('../assets/freebet-bg.jpg');
  }

  &.freespin {
    background-image: url('../assets/freespin-bg.jpg');
  }

  &.mobile {
    min-width: 104px;
    padding: var(--spacing-150) var(--spacing-100);

    .bonus-title {
      margin-top: var(--spacing-050);
      font: var(--mobile-caption-1-medium);
    }

    .bonus-value {
      /* stylelint-disable */
      font-family: 'SF Pro Display';
      font-size: 24px;
      font-weight: 600;
      font-style: normal;
      line-height: 28px;
      text-align: center;
      /* stylelint-enable */
    }

    .bonus-badge {
      font: var(--mobile-caption-2-medium);
      border: 1.6px solid var(--background-base, #111118);
    }

    .circle-check {
      padding: 3.2px; /* stylelint-disable-line */
    }
  }
}
</style>
