<template>
  <div
    class="third-step"
    :class="{ mobile: isMobile }"
    data-t="third-step-form"
  >
    <div class="content">
      <template v-if="isLoading">
        <h1 class="title-loader">
          <StSkeletonLoader
            height="24px"
            width="200px"
            :shimmer-opacity="0.28"
            bg-color="var(--background-primary)"
          />
        </h1>
        <p class="subtitle-loader">
          <StSkeletonLoader
            height="16px"
            width="100%"
            :shimmer-opacity="0.28"
            bg-color="var(--background-primary)"
          />
          <StSkeletonLoader
            height="16px"
            width="300px"
            :shimmer-opacity="0.28"
            bg-color="var(--background-primary)"
          />
        </p>
      </template>
      <template v-else>
        <h1 class="title">
          {{ t('welcomeBonus.title') }}
        </h1>
        <p class="subtitle">
          {{ subtitle }}
        </p>
      </template>
      <div v-if="isLoading" class="bonuses">
        <StSkeletonLoader
          v-for="i in 3"
          :key="i"
          height="138px"
          width="120px"
          :shimmer-opacity="0.28"
          bg-color="var(--background-primary)"
        />
      </div>
      <div v-else-if="hasAnyBonus" class="bonuses">
        <DepositBonusCard
          v-if="hasDepositBonus"
          :welcome-bonus="welcomeBonus"
          :is-mobile="isMobile"
        />
        <FreespinCard
          v-if="hasFreespin"
          :welcome-bonus="welcomeBonus"
          :is-mobile="isMobile"
        />
        <FreebetCard
          v-if="hasFreebet"
          :welcome-bonus="welcomeBonus"
          :is-mobile="isMobile"
        />
        <ReloadBonusCard
          v-if="hasReload"
          :welcome-bonus="welcomeBonus"
          :is-mobile="isMobile"
        />
      </div>
      <div v-else class="bonuses">
        <WelcomeBonusCard
          type="freebet"
          :title="t('welcomeBonus.freebet')"
          :placeholder="t('welcomeBonus.freebetPlaceholder')"
        />
        <WelcomeBonusCard
          type="freespin"
          :title="t('welcomeBonus.freespins')"
          :placeholder="t('welcomeBonus.freespinsPlaceholder')"
        />
        <WelcomeBonusCard
          type="reload"
          :title="t('welcomeBonus.reload')"
          :placeholder="t('welcomeBonus.reloadPlaceholder')"
        />
      </div>
    </div>
    <div class="footer">
      <p v-if="isLoading" class="info">
        {{ t('welcomeBonus.preparingBonuses') }}
      </p>
      <StButton
        :size="isMobile ? 'l' : 'xl'"
        block
        class="claim-button"
        :loading="isLoading"
        v-bind="buttonProps"
      />
      <ClientOnly>
        <StLottie
          v-if="isShownAnimation"
          class="animation"
          :animation-data="welcomeBonusAnimation"
          @complete="handleAnimationComplete"
        />
      </ClientOnly>
    </div>
  </div>
</template>

<script setup lang="ts">
import { delay } from '@st/utils'
import type { IconName } from '@st/ui/components/StIcon/types'
import welcomeBonusAnimation from './assets/welcome-bonus-animation.json'
import { useWelcomeBonus } from './useWelcomeBonus'
import DepositBonusCard from './parts/DepositBonusCard.vue'
import FreebetCard from './parts/FreebetCard.vue'
import FreespinCard from './parts/FreespinCard.vue'
import ReloadBonusCard from './parts/ReloadBonusCard.vue'
import WelcomeBonusCard from './parts/WelcomeBonusCard.vue'
import { useUserStore } from '../../stores/useUserStore'

defineProps<{
  isMobile?: boolean
}>()

const { t } = useI18n()
const {
  welcomeBonus,
  hasReload,
  hasFreebet,
  hasFreespin,
  hasAnyBonus,
  hasDepositBonus,
  isLoading,
  fetchWelcomeBonus,
} = useWelcomeBonus()

const isShownAnimation = ref(false)
function handleAnimationComplete() {
  isShownAnimation.value = false
}

const { isAuthenticated } = storeToRefs(useUserStore())

onMounted(async () => {
  await until(isAuthenticated).toBe(true)

  fetchWelcomeBonus()

  await delay(100)
  await until(isLoading).toBe(false)

  isShownAnimation.value = true
})

const subtitle = computed(() =>
  hasAnyBonus.value
    ? t('welcomeBonus.subtitle')
    : t('welcomeBonus.yourAccountIsCreated'),
)

const buttonProps = computed(() =>
  hasAnyBonus.value
    ? {
        label: t('welcomeBonus.claimBonuses'),
        to: '/bonuses',
      }
    : {
        label: t('welcomeBonus.startPlaying'),
        to: '/',
        icon: 'play-solid' as IconName,
        isLeftIcon: true,
      },
)
</script>

<style scoped>
.content {
  flex-grow: 1;
}

.title {
  margin: 0;
  font: var(--desktop-text-xl-semibold);
  text-align: center;
}

.subtitle {
  margin: 0;
  margin-top: var(--spacing-100);

  font: var(--desktop-text-md-medium);
  color: var(--text-tertiary);
  text-align: center;
}

.title-loader {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  height: 32px;
}

.subtitle-loader {
  display: flex;
  flex-direction: column;
  gap: var(--spacing-100);
  align-items: center;

  margin-top: var(--spacing-100);
}

.bonuses {
  display: flex;
  flex-wrap: wrap;
  gap: var(--spacing-125);
  justify-content: center;

  margin-top: var(--spacing-400);
}

.animation {
  pointer-events: none;
  position: absolute;
  bottom: 0;
}

.footer {
  position: relative;
}

.info {
  margin: var(--spacing-250) 0;
  font: var(--desktop-text-xs-medium);
  text-align: center;
}

.claim-button {
  position: relative;
  z-index: 1;
  margin-top: var(--spacing-400);
}

.third-step {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: var(--spacing-300) 0;

  &.mobile {
    .title {
      font: var(--mobile-title-2-semibold);
      text-align: left;
    }

    .subtitle {
      font: var(--mobile-text-content-regular);
      text-align: left;
    }

    .bonuses {
      gap: var(--spacing-100);
      margin-top: var(--spacing-300);
    }
  }
}

/* stylelint-disable */
.mobile {
  .third-step {
    padding: var(--spacing-100) 0 0;
  }

  .subtitle {
    margin-top: 0;
  }
}
/* stylelint-enable */
</style>
