<template>
  <WelcomeBonusCard
    :amount="welcomeBonus.reloadProgramAmount"
    :currency-id="welcomeBonus.reloadProgramCurrencyId"
    :badge="badge"
    :title="t('welcomeBonus.reload')"
    :is-mobile="isMobile"
    type="reload"
  >
    <template #tooltip>
      <ReloadBonusTooltip />
    </template>
  </WelcomeBonusCard>
</template>

<script setup lang="ts">
import WelcomeBonusCard from './WelcomeBonusCard.vue'
import type { WelcomeBonus } from '../types'
import ReloadBonusTooltip from './ReloadBonusTooltip.vue'

const props = defineProps<{
  welcomeBonus: WelcomeBonus
  isMobile?: boolean
}>()

const { t } = useI18n()

const badge = computed(() => {
  const hours = props.welcomeBonus.reloadProgramRepeatIntervalInHours ?? 0

  if (hours < 48) {
    return t('welcomeBonus.reloadPeriodInHours', hours)
  }

  const days = Math.ceil(hours / 24)
  return t('welcomeBonus.reloadPeriodInDays', days)
})
</script>
